'use client'

import { SWRConfig } from 'swr'
import { ClientPerson } from '../../../clientModels/ClientPerson'
import { UniversalSearch } from '.'
import { useRouter } from 'next/navigation'
import { urls } from '../../../urls'

export function ClientUniversalSearch() {
  const router = useRouter()
  return (
    <SWRConfig
      value={{
        fetcher: resource =>
          fetch(resource)
            .then(res => res.json())
            .then(res => {
              const data: { people: ClientPerson[] } = res
              return data.people
            }),
      }}
    >
      <UniversalSearch
        onSelectItem={slug => {
          router.push(urls.personCryptoDetails(slug))
        }}
      />
    </SWRConfig>
  )
}
