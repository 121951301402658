'use client'

import { useState } from 'react'
import { useDebounce } from 'react-use'
import useSWR from 'swr'
import { ClientPerson } from '../../../clientModels/ClientPerson'
import { personUtils } from '../../../modelUtils/personUtils'
import { PersonAvatar } from '../Avatar'
import {
  CommandDialogContent,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from '../Command'
import { Dialog, DialogTrigger } from '../Dialog'
import { Input } from '../Input'
import { ClientPersonRole } from '../../../clientModels/ClientPersonRole'
import { FormattedRoleWithinContext } from '../../stances/FormattedRole'
import { InputWithIcons } from '../InputWithIcons'
import { SearchIcon } from 'lucide-react'

export type UniversalSearchPerson = ClientPerson & { primaryRole: ClientPersonRole | null }

export function UniversalSearch({
  onSelectItem: _onSelectItem,
}: {
  onSelectItem: (slug: string) => void
}) {
  const [value, setValue] = useState('')
  const [open, setIsOpen] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState(value)
  useDebounce(() => setDebouncedValue(value), 1000, [value])
  function onSelectItem(slug: string) {
    _onSelectItem(slug)
    setIsOpen(false)
  }
  const { data, error, isLoading } = useSWR<UniversalSearchPerson[]>(
    debouncedValue ? `/api/public/search/${encodeURIComponent(debouncedValue)}` : null,
    { keepPreviousData: false },
  )

  const showLoadingState = isLoading || (value && value !== debouncedValue)
  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <InputWithIcons
          leftIcon={<SearchIcon className="h-4 w-4" />}
          type="button"
          className="mx-auto w-full cursor-pointer"
          value="Search for a person..."
        />
      </DialogTrigger>
      <CommandDialogContent shouldFilter={false}>
        <CommandInput onValueChange={setValue} value={value} placeholder="Search for a person..." />
        <CommandList>
          {!!value && (
            <CommandEmpty>
              {showLoadingState ? 'Fetching results...' : 'No results found.'}
            </CommandEmpty>
          )}

          {showLoadingState || !value || !data?.length ? null : (
            <>
              {data?.map(person => (
                <CommandItem
                  onSelect={() => onSelectItem(person.slug)}
                  key={person.slug}
                  value={person.slug}
                  className="flex items-center gap-4"
                >
                  <PersonAvatar person={person} size={40} />

                  <div>
                    <div className="text-lg">{personUtils.fullName(person)}</div>
                    {person.primaryRole && (
                      <div className="text-sm">
                        <FormattedRoleWithinContext role={person.primaryRole} person={person} />
                      </div>
                    )}
                  </div>
                </CommandItem>
              ))}
            </>
          )}
        </CommandList>
      </CommandDialogContent>
    </Dialog>
  )
}
